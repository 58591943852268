import React from "react";

import { InlineWidget } from "react-calendly";
function GetInTouch() {
  return (
    <section className="contact" id="contactUs">
      <div className="container-fluid ts-container-fluid">
        <div className="contact__content  text-center">
          <p className="ts-fs-3-ii contact__pretitle mb-0 mb-sm-3">
            Get in touch.
          </p>
          {/* <a
            href="mailto:hello@dsrptvcapital.com"
            className="ts-fs-2 text-decoration-none fw-semibold"
          >
            hello@dsrptvcapital.com
          </a> */}
          <InlineWidget
            url="https://calendly.com/jbdsrptv/contact-us?hide_event_type_details=1&hide_gdpr_banner=1&background_color=bac0b7&text_color=403c3c&primary_color=fdfdfa"
            styles={{ height: "700px" }}
          />
        </div>
      </div>
    </section>
  );
}

export default GetInTouch;
